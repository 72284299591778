@font-face {
  font-family: 'Hamlet';
  src: local('Hamlet'), url(./assets/Hamletornot.ttf) format('truetype');
}
@font-face {
  font-family: 'Ancient';
  src: url(./assets/jancient.ttf) format('truetype');
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: grey;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #a36528;
}

body {
  background: grey;
  background-image: url(./assets/background.png);
  background-repeat: repeat;
}

h1, h2, h3, h4, h5, p {
  margin: 0;
  letter-spacing: 1.5px;
}

h1 {
  font-weight: unset;
  color: whitesmoke;
  font-family: 'Hamlet';
  font-size: 38px;
}

h2 {
  color: #a36528;
  font-family: 'Hamlet';
}

h3 {
  font-size: 18px;
  color: grey;
  font-family: 'Ancient';
}

h4 {
  font-weight: 100;
  color: grey;
  font-family: 'Ancient';
  font-size: 17px;
}

h5 {
  font-weight: 100;
  font-family: 'Ancient';
  font-size: 16px;
}

ul {
  margin: 0;
  padding-left: 25px;
}

input {
  border: none;
  text-align: right;
  padding: 5px;
  background: whitesmoke;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

 select {
  border: none;
  border-radius: 5px;
  padding: 8px;
  margin-right: 5px
 }

 .select-label {
  margin-right: 5px;
 }

.tab-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #a36528;
  padding: 0 24px;
  border-width: 2px;
  box-shadow: 5px 5px 15px 5px #000;
}

.title-shell {
  display: flex;
  align-items: center;
}

.input-shell {
  padding: 0 0 15px;
  display: flex;
  justify-content: space-between;
}

.input-shell div {
  display: flex;
  justify-content: start;
  align-items: center;
}

.input-shell p {
  padding-left: 5px;
  color: whitesmoke;
  font-family: 'Ancient';
  font-size: 18px;
}

.accordion-shell {
  border-radius: 5px;
}

.accordion-shell-talisman {
  border-radius: 5px;
  background-color: #ffffff;
}

.talisman-shell {
  padding: 12px 16px;
  display: flex;
  border-bottom: 2px solid #dedede;
}

.item-title-shell {
  display: flex;
  justify-content: start;
  align-items: baseline;
}

.item-title-shell p {
  color: gray;
  margin-left: 5px;
  font-family: 'Ancient';
  font-size: 18px;
}

.modal {
  padding: 10px;

}

.pop-up {
  align-items: center;
  margin-bottom: 5px;
}

.popup-content {
  overflow: auto;
  max-height: 100%;
  border-radius: 5px;
}

.potion-shell {
  display: flex;
}

.potion-shell img, .talisman-shell img {
  margin-right: 10px;
}

.construction-title {
  display: flex;
  align-items: center;
}

.construction-title h3 {
  color: whitesmoke;
}

.construction-shell {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
}

.item-detail-shell {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  flex-direction: column;
  height: 150px;
  overflow: auto;
  border-top: 1px solid lightgrey;
  padding-top: 15px;
}

.item-detail {
  display: flex;
  align-items: baseline;
}

.id-details {
  vertical-align: baseline;
}

.item-detail p {
  margin-left: 5px;
}

.item-detail img {
  transition: .6s;
  margin-left: 10px;
  padding: 0 5px;
  vertical-align: bottom; 
}

.item-detail img:hover {
  background: lightgrey;
  border-radius: 25%;
}

.bottom-margin {
  margin-bottom: 10px;
}

.subject-shell { 
  margin-left: 15px;
}

.enchanted-item-image {
  position: relative;
  height: 50px;
}

.major-item {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 0 !important;
}

.display-none {
  display: none;
}

.fa-copy {
  color: lightgray;
  margin-left: 5px
}

.fa-copy:hover {
  color: black
}

.span-flex {
  display: flex;
}